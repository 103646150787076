import api from "./api";

const route = "/pages";

const PagesService = {
  getPages: () => api.get(`${route}/admin/all`),
  getSinglePage: (page_id) => api.get(`${route}/admin/single/${page_id}`),
  postPage: (page) => api.post(route, { ...page }),
  putPage: (page) => api.put(route, { ...page }),
  deletePage: (page_id) => api.delete(`${route}/${page_id}`),
};

export default PagesService;
