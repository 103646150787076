import React, { useContext, useEffect } from "react";
import MapaLugares from "../clases/MapaLugares";
import { ClassTypeContext } from "../../context/ClassTypesContext";
import { hideModal } from "../../utils";
import ImageInput from "../common/ImageInput";
import { AppConfigContext } from "../../context/AppConfigContext";

const ClassTypeForm = ({ class_type_id }) => {
  const {
    class_type,
    getClassType,
    createClassType,
    setPropiedadClassType,
    postClassType,
  } = useContext(ClassTypeContext);

  const { single_class_enabled } = useContext(AppConfigContext);

  useEffect(() => {
    if (isNaN(class_type_id)) {
      createClassType();
    } else {
      getClassType(class_type_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_type_id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    postClassType(class_type);
  };

  const renderForm = () => {
    if (class_type && class_type !== null) {
      const { name, description, icon, color, mapa, file } = class_type;
      let lugares = String(mapa)
        .split(",")
        .map((num) => parseInt(num))
        .filter((num) => !isNaN(num));
      return (
        <form onSubmit={handleSubmit}>
          <label>Nombre</label>
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => setPropiedadClassType("name", e.target.value)}
          />
          <label>Descripción</label>
          <input
            type="text"
            className="form-control mb-3"
            value={description}
            onChange={(e) =>
              setPropiedadClassType("description", e.target.value)
            }
          />
          <ImageInput
            value={file}
            prop="picture"
            modifier={setPropiedadClassType}
          />
          <label>Icono</label>
          <input
            type="text"
            className="form-control mb-3"
            value={icon}
            onChange={(e) => setPropiedadClassType("icon", e.target.value)}
          />
          <label>Color</label>
          <input
            type="color"
            className="form-control mb-3"
            value={color}
            onChange={(e) => setPropiedadClassType("color", e.target.value)}
          />
          {single_class_enabled && (
            <div>
              <label>Lugares</label>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Número de columnas por fila, separado por comas"
                value={mapa}
                onChange={(e) => setPropiedadClassType("mapa", e.target.value)}
              />
            </div>
          )}
          {lugares.length > 0 && <MapaLugares rows={lugares} icon={icon} />}
          <div className="row">
            <div className="col-6">
              <button type="submit" className="btn btn-primary w-100 mt-3">
                Guardar
              </button>
            </div>
            <div className="col-6">
              <button
                type="button"
                onClick={hideModal}
                className="btn btn-link text-muted w-100 mt-3"
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div className="container-fluid px-0">{renderForm()}</div>;
};

export default ClassTypeForm;
