import { Router } from "@reach/router";
import React from "react";
import AdminFreeTrials from "./admin/AdminFreeTrials";
import AnaliticasIngresos from "./analiticas/AnaliticasIngresos";
import AnaliticasInscritos from "./analiticas/AnaliticasInscritos";
import AnaliticasInstructores from "./analiticas/AnaliticasInstructores";
import AnaliticasPaquetes from "./analiticas/AnaliticasPaquetes";
import AnaliticasReservaciones from "./analiticas/AnaliticasReservaciones";
import AnaliticasVideos from "./analiticas/AnaliticasVideos";

const Analitica = () => {
  return (
    <div>
      <Router>
        <AnaliticasInscritos path="/inscritos" default />
        <AnaliticasPaquetes path="/paquetes" />
        <AnaliticasIngresos path="/ingresos" />
        <AnaliticasReservaciones path="/reservaciones" />
        <AnaliticasInstructores path="/instructores" />
        <AnaliticasVideos path="/videos" />
        <AdminFreeTrials path="/free" />
      </Router>
    </div>
  );
};

export default Analitica;
