import { ClassInstructorProvider } from "./context/SingleClassContext";
import { CoachesProvider } from "./context/CoachesContext";
import { CustomerProvider } from "./context/CustomerContext";
import { HomeProvider } from "./context/HomeContext";
import { LocationsProvider } from "./context/LocationsContext";
import { ModalProvider } from "./context/ModalContext";
import { PurchaseProvider } from "./context/PurchasesContext";
import { PaquetesProvider } from "./context/PackageContext";
import { UserProvider } from "./context/UserContext";
import { ClassTypeProvider } from "./context/ClassTypesContext";
import { ClassCategoryProvider } from "./context/ClassCategoryContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { DiscountsProvider } from "./context/DiscountsContext";
import { AnaliticasProvider } from "./context/AnaliticasContext";
import { MetodosProvider } from "./context/MetodosContext";
import { ReservationsProvider } from "./context/ReservationsContext";
import Main from "./Main";
import "./index.css";
import { VideosProvider } from "./context/VideosContext";
import { VideoTypeProvider } from "./context/VideoTypeContext";
import { ProgramsProvider } from "./context/ProgramsContext";
import { UsersProvider } from "./context/UsersContext";
import { InvoicesProvider } from "./context/InvoicesContext";
import { PagesProvider } from "./context/PagesContext";
import { combineComponents } from "./context";
import { FiltersProvider } from "./context/FiltersContext";
import { PaymentMethodsProvider } from "./context/PaymentMethodsContext";
import {
  AppConfigContext,
  AppConfigProvider,
} from "./context/AppConfigContext";
import { useContext, useEffect } from "react";

const AppContextProvider = combineComponents([
  HomeProvider,
  UserProvider,
  UsersProvider,
  PagesProvider,
  VideosProvider,
  FiltersProvider,
  MetodosProvider,
  CoachesProvider,
  CustomerProvider,
  InvoicesProvider,
  ProgramsProvider,
  PurchaseProvider,
  PaquetesProvider,
  CheckoutProvider,
  VideoTypeProvider,
  ClassTypeProvider,
  LocationsProvider,
  DiscountsProvider,
  AnaliticasProvider,
  ReservationsProvider,
  ClassCategoryProvider,
  PaymentMethodsProvider,
  ClassInstructorProvider,
]);

const AppContext = () => {
  const { firebaseConfig, getAppConfig } = useContext(AppConfigContext);

  useEffect(() => {
    getAppConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderApp = () => {
    if (firebaseConfig && firebaseConfig !== null) {
      return (
        <AppContextProvider>
          <Main />
        </AppContextProvider>
      );
    }
  };

  return <div>{renderApp()}</div>;
};

const App = () => {
  return (
    <AppConfigProvider>
      <ModalProvider>
        <AppContext />
      </ModalProvider>
    </AppConfigProvider>
  );
};

export default App;
