import React from "react";
import Switch from "react-switch";
import { Link } from "@reach/router";
import SpecialEventOptions from "./SpecialEventOptions";
import SingleClassOptions from "./SingleClassOptions";
import SubscriptionOptions from "./SubscriptionOptions";

const PaqueteForm = ({
  spinner,
  class_package,
  modifier,
  postPaquete,
  single_class_enabled,
}) => {
  const {
    title,
    price,
    available,
    sale_price,
    description,
    customer_limit,
    is_subscription,
    is_special_event,
    short_description,
    limit_per_customer,
  } = class_package;

  const handleSubmit = (e) => {
    e.preventDefault();
    postPaquete(class_package);
  };

  const addDefaultPeriod = () => {
    modifier("subscription_period", "month");
    modifier("subscription_interval", "1");
  };

  const renderSingleClassOptions = () => {
    if (parseInt(single_class_enabled) === 1) {
      return (
        <SingleClassOptions class_package={class_package} modifier={modifier} />
      );
    }
  };

  const renderSubscriptionOptions = () => {
    if (is_subscription) {
      return (
        <SubscriptionOptions
          class_package={class_package}
          modifier={modifier}
        />
      );
    }
  };

  const renderSpeciaEventOptions = () => {
    if (is_special_event) {
      return (
        <SpecialEventOptions
          class_package={class_package}
          modifier={modifier}
        />
      );
    }
  };

  return (
    <div className="container-fluid px-0">
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-6">
            <label>Disponible</label>
          </div>
          <div className="col-6">
            <Switch
              checked={available}
              onChange={(checked) => modifier("available", checked)}
            />
          </div>
        </div>
        <label>Nombre</label>
        <input
          type="text"
          className="form-control mb-3"
          value={title}
          onChange={(e) => modifier("title", e.target.value)}
        />
        <label>Descripción Corta</label>
        <input
          type="text"
          className="form-control mb-3"
          value={short_description !== null ? short_description : ""}
          onChange={(e) => modifier("short_description", e.target.value)}
        />
        <label>Descripción</label>
        <input
          type="text"
          className="form-control mb-3"
          value={description !== null ? description : ""}
          onChange={(e) => modifier("description", e.target.value)}
        />
        <label>Precio</label>
        <input
          type="number"
          className="form-control mb-3"
          value={price}
          onChange={(e) => modifier("price", e.target.value)}
        />
        <label>Precio de Oferta</label>
        <input
          type="text"
          className="form-control mb-3"
          value={sale_price}
          onChange={(e) => modifier("sale_price", e.target.value)}
        />
        {renderSingleClassOptions()}
        {renderSpeciaEventOptions()}
        {!class_package.is_special_event && (
          <div>
            <h4 className="border-bottom pb-2">Suscripción</h4>
            <div className="row mb-2">
              <div className="col-6">
                <label>¿Es cargo recurrente?</label>
              </div>
              <div className="col-6">
                <Switch
                  checked={class_package.is_subscription}
                  onChange={(checked) => {
                    modifier("is_subscription", checked);
                    if (checked) addDefaultPeriod();
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {renderSubscriptionOptions()}
        <h4 className="border-bottom pb-2">Restricciones</h4>
        <label>Límite de Clientes</label>
        <input
          type="number"
          className="form-control mb-3"
          value={customer_limit !== null ? customer_limit : ""}
          placeholder="Dejar en blanco para desactivar"
          onChange={(e) => modifier("customer_limit", e.target.value)}
        />
        <label>Límite de Compras por Cliente</label>
        <input
          type="number"
          className="form-control mb-3"
          value={limit_per_customer !== null ? limit_per_customer : ""}
          placeholder="Dejar en blanco para desactivar"
          onChange={(e) => modifier("limit_per_customer", e.target.value)}
        />
        <div className="row">
          <div className="col-6">
            <button type="submit" className="btn btn-primary">
              {spinner ? <div className="spinner-border"></div> : "Guardar"}
            </button>
          </div>
          <div className="col-6 text-right">
            <Link to="/myadmin/videos" className="btn btn-link text-secondary">
              Cancelar
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PaqueteForm;
