import React from "react";
import { Link } from "@reach/router";
import { formatMonto } from "../../utils";
import moment from "moment";

const InvoiceRow = ({ user, invoice, customer_id }) => {
  const renderPaymentMethod = () => {
    if (invoice.purchase && invoice.purchase !== null) {
      if (
        invoice.purchase.payment_method &&
        invoice.purchase.payment_method !== null
      ) {
        return invoice.purchase.payment_method.name;
      }
    }
    return "N/A";
  };

  const renderSiguienteCargo = () => {
    if (invoice.purchase && invoice.purchase !== null) {
      if (
        invoice.purchase.status !== "cancelled" &&
        invoice.purchase.status !== "pending" &&
        invoice.purchase.subscription_id !== null &&
        invoice.status !== "failed"
      ) {
        const initial = moment(invoice.createdAt).utc();
        if (
          invoice.purchase.free_trial_length !== null &&
          invoice.purchase.free_trial_length &&
          moment(invoice.createdAt).isBefore(
            moment(invoice.purchase.createdAt).add(
              invoice.purchase.free_trial_length,
              "days"
            )
          )
        ) {
          initial.add(invoice.purchase.free_trial_length, "days");
        } else {
          initial.add(
            invoice.purchase.subscription_interval,
            invoice.purchase.subscription_period
          );
        }
        return initial.format("DD MMM YYYY");
      }
    }
  };

  const renderClassPlackage = () => {
    if (invoice.class_package !== null) {
      return invoice.class_package.title;
    }
  };

  return (
    <tr className="hover-light border-bottom small align-middle">
      <td className="id-td">{invoice.invoice_id}</td>
      <td className="id-td">
        {invoice.purchase_id !== null ? invoice.purchase_id : "N/A"}
      </td>
      <td>{moment(invoice.createdAt).format("DD MMM YYYY HH:mm")}</td>
      <td>{renderClassPlackage()}</td>
      {!customer_id && (
        <td>
          <Link to={`/myadmin/customer/${invoice.customer_id}`}>
            {invoice.customer.name} {invoice.customer.last_name}{" "}
          </Link>
        </td>
      )}
      <td>
        {user.role === "admin" && (
          <>
            {"$"}
            {formatMonto(invoice.amount)}
          </>
        )}
      </td>
      <td>
        {invoice.status === "completed" ? (
          <span className="badge badge-pill bg-success">Completado</span>
        ) : invoice.status === "failed" ? (
          <span className="badge badge-pill bg-danger">Fallido</span>
        ) : (
          <span className="badge badge-pull bg-secondary text-capitalize">
            {invoice.status}
          </span>
        )}
      </td>
      <td>{renderSiguienteCargo()}</td>

      <td colSpan={2}>{renderPaymentMethod()}</td>
    </tr>
  );
};

export default InvoiceRow;
