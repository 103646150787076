import React, { useState } from "react";
import ScheduleDay from "./ScheduleDay";
import ScheduleClass from "./ScheduleClass";
import moment from "moment";

const ScheduleWeek = ({
  week,
  location,
  isHome,
  filtered,
  layout,
  hideMobile,
}) => {
  const [day, setDay] = useState(null);

  const getDetails = (day) => {
    const details =
      location !== null && location !== ""
        ? day.details.filter(
            (detail) => parseInt(detail.location_id) === parseInt(location)
          )
        : day.details;
    const sorted = details.sort((a, b) =>
      moment(a.class_date).format("HH:mm") >
      moment(b.class_date).format("HH:mm")
        ? 1
        : -1
    );
    return sorted;
  };

  const getDayIndex = () => {
    const date = moment(day.date);
    const current = date.startOf("week");
    let index = 1;
    for (let i = 0; i < 7; i++) {
      if (current.isAfter(date)) {
        index = i + 1;
        break;
      }
      current.add(1, "day");
    }
    index += 1;
    return index;
  };

  const renderSingleClasses = () => {
    if (day !== null) {
      return (
        <div className="schedule-classes-container shadow-sm">
          <h4 className="mb-1 pb-1 border-bottom">
            {moment(day.date).format("MMM DD")}
          </h4>
          {getDetails(day).map((clase) => (
            <ScheduleClass key={clase.single_class_id} singleClass={clase} />
          ))}
        </div>
      );
    }
  };
  const renderArrow = () => {
    if (day !== null) {
      return (
        <div
          className="container-fluid top-arrow"
          style={{ paddingLeft: `${getDayIndex() * 105}px` }}
        ></div>
      );
    }
  };

  const renderTime = (index) => {
    const initial = moment().startOf("day");
    const minutes = index * 15;
    initial.add(minutes, "minutes");
    return initial.format("HH : mm");
  };

  const renderRows = () => {
    return new Array(96)
      .fill(1)
      .map((one, index) => {
        const time = renderTime(index);
        const single_classes = week.find((day) => {
          return day.details.find(
            (single_class) =>
              moment(single_class.class_date).utc().format("HH : mm") === time
          );
        });
        if (single_classes) {
          return (
            <div className="row" key={index}>
              <div className="schedule-col time-container text-center bold border-bottom bg-dark text-white py-4">
                <div className="row align-items-center h-100">
                  <span>{renderTime(index)}</span>
                </div>
              </div>
              {week.map((currentDay) => (
                <ScheduleDay
                  key={currentDay.date}
                  day={currentDay}
                  clases={getDetails(currentDay, time)}
                  location={location}
                  isHome={isHome}
                  filtered={filtered}
                />
              ))}
            </div>
          );
        }
        return null;
      })
      .filter((object) => object !== null);
  };

  const renderLayout = () => {
    if (layout === "week") {
      return (
        <div>
          <div className="row">
            <div className="schedule-col text-center bold border-bottom bg-dark text-white py-4">
              <div className="row align-items-center h-100">
                <span>Horario</span>
              </div>
            </div>
            {week.map((currentDay) => (
              <div key={currentDay.date} className="schedule-col border">
                <div className="row mx-0 pt-2">
                  <div className="container-fluid text-center">
                    {moment(currentDay.date).utc().format("DD")}
                  </div>
                </div>
                <div className="row mx-0 my-3 bg-dark text-white">
                  <div className="container-fluid text-center">
                    {moment(currentDay.date).utc().format("dd")}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {renderRows()}
        </div>
      );
    }
    return (
      <div className={`row ${hideMobile ? "hide-mobile" : ""}`}>
        {week.map((currentDay, index) => (
          <ScheduleDay
            key={index}
            day={currentDay}
            clases={getDetails(currentDay).length}
            location={location}
            isHome={isHome}
            filtered={filtered}
            setDay={() => {
              if (day !== null) {
                if (day.date === currentDay.date) {
                  return setDay(null);
                }
              }
              setDay(currentDay);
            }}
          />
        ))}
        {renderArrow()}
        <div className="container-fluid px-0 float-left">
          {renderSingleClasses()}
        </div>
      </div>
    );
  };

  return renderLayout();
};

export default ScheduleWeek;
