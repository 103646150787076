import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";

const ClassTypeRow = ({ category, editClassType, confirmDelete }) => {
  const { single_class_enabled } = useContext(AppConfigContext);

  return (
    <tr className="small bg-white border-bottom align-middle hover-light">
      <td>{category.name}</td>
      <td>
        <i className={category.icon}></i>
      </td>
      <td>
        <div
          className="color-legend"
          style={{ backgroundColor: category.color }}
        ></div>
      </td>
      {single_class_enabled && <td>{category.mapa}</td>}
      <td>
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => editClassType(category.class_type_id)}
        >
          <i className="fa fa-edit me-1"></i> Editar
        </button>
        <button
          className="btn btn-outline-danger btn-sm mx-3"
          onClick={() => confirmDelete(category)}
        >
          <i className="fa fa-trash me-1"></i> Eliminar
        </button>
      </td>
    </tr>
  );
};

export default ClassTypeRow;
