import { Link } from "@reach/router";
import React from "react";

const ManagerTabs = ({ single_class_enabled, videos_enabled }) => {
  return (
    <ul className="side-menu-list">
      {parseInt(single_class_enabled) === 1 && (
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#clasesDropdown"
            role="button"
            data-bs-toggle="collapse"
            aria-expanded="false"
            aria-controls="clasesDropdown"
          >
            Clases
          </a>
          <ul className="collapse collapse-menu" id="clasesDropdown">
            <li className="nav-item text-item">
              <Link to="/myadmin/clases" className="nav-link">
                Horarios
              </Link>
            </li>
            <li className="nav-item text-item">
              <Link to="/myadmin/asistentes" className="nav-link">
                Asistentes
              </Link>
            </li>
          </ul>
        </li>
      )}
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#clientesDropdown"
          role="button"
          data-bs-toggle="collapse"
          aria-expanded="false"
          aria-controls="clientesDropdown"
        >
          Clientes
        </a>
        <ul id="clientesDropdown" className="collapse collapse-menu">
          <li className="nav-item text-item">
            <Link to="/myadmin/customers" className="nav-link">
              Clientes
            </Link>
          </li>
          <li className="nav-item text-item">
            <Link to="/myadmin/orders" className="nav-link">
              Pagos
            </Link>
          </li>
          {parseInt(videos_enabled) === 1 ? (
            <li className="nav-item text-item">
              <Link to="/myadmin/online" className="nav-link">
                Online
              </Link>
            </li>
          ) : (
            ""
          )}
          {parseInt(single_class_enabled) === 1 ? (
            <li className="nav-item text-item">
              <Link to="/myadmin/presenciales" className="nav-link">
                Presenciales
              </Link>
            </li>
          ) : (
            ""
          )}
        </ul>
      </li>
      {parseInt(single_class_enabled) === 1 ? (
        <>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#paquetesDropdown"
              role="button"
              data-bs-toggle="collapse"
              aria-expanded="false"
              aria-controls="paquetesDropdown"
            >
              Paquetes
            </a>
            <ul className="collapse collapse-menu" id="paquetesDropdown">
              <li className="nav-item text-item">
                <Link to="/myadmin/paquetes" className="nav-link">
                  Regulares
                </Link>
              </li>
              <li className="nav-item text-item">
                <Link to="/myadmin/especiales" className="nav-link">
                  Especiales
                </Link>
              </li>
            </ul>
          </li>
        </>
      ) : (
        <li className="nav-item text-item">
          <Link to="/myadmin/paquetes" className="nav-link">
            Paquetes
          </Link>
        </li>
      )}
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#atributosDropdown"
          role="button"
          data-bs-toggle="collapse"
          aria-expanded="false"
          aria-controls="atributosDropdown"
        >
          Atributos
        </a>
        <ul className="collapse collapse-menu" id="atributosDropdown">
          <li className="nav-item text-item">
            <Link to="/myadmin/class_types" className="nav-link">
              Tipos de Clase
            </Link>
          </li>
          <li className="nav-item text-item">
            <Link to="/myadmin/coaches" className="nav-link">
              Coaches
            </Link>
          </li>
          {parseInt(videos_enabled) === 1 ? (
            <>
              <li className="nav-item text-item">
                <Link to="/myadmin/categorias" className="nav-link">
                  Categorías
                </Link>
              </li>
              <li className="nav-item text-item">
                <Link to="/myadmin/video_types" className="nav-link">
                  Tipos de Video
                </Link>
              </li>
            </>
          ) : (
            ""
          )}
        </ul>
      </li>
      <li className="nav-item text-item">
        <Link to="/myadmin/descuentos" className="nav-link">
          Descuentos
        </Link>
      </li>
      {parseInt(videos_enabled) === 1 ? (
        <li className="nav-item text-item">
          <Link to="/myadmin/videos" className="nav-link">
            Videos
          </Link>
        </li>
      ) : (
        ""
      )}
      <li className="nav-item text-item">
        <Link to="/myadmin/users" className="nav-link">
          Usuarios
        </Link>
      </li>
      {/*<li className="nav-item text-item">
        <Link to="/myadmin/pages" className="nav-link">
          Páginas
        </Link>
      </li>*/}
    </ul>
  );
};

export default ManagerTabs;
