import moment from "moment";
import React, { useContext, useEffect } from "react";
import { ReservationsContext } from "../../context/ReservationsContext";

const AdminSingleReservation = ({ class_reservation_id }) => {
  const { reservation, postAttend, getSingleReservation } =
    useContext(ReservationsContext);

  useEffect(() => {
    getSingleReservation(class_reservation_id);
  }, [class_reservation_id]);

  const renderIcon = (single_class) => {
    if (single_class && single_class !== null) {
      const { class_type } = single_class;
      if (class_type && class_type !== null) {
        return <i className={class_type.icon}></i>;
      }
    }
  };

  const renderName = (single_class) => {
    const { class_type } = single_class;
    if (class_type && class_type !== null) {
      return single_class.class_type.name;
    }
  };

  const renderCustomer = () => {
    if (reservation && reservation !== null) {
      const { customer } = reservation;
      return (
        <div>
          <h1>
            {customer.name} {customer.last_name}
          </h1>
          <p>{customer.email}</p>
        </div>
      );
    }
  };

  const renderReservation = () => {
    if (reservation && reservation !== null) {
      const { single_class } = reservation;
      if (single_class && single_class !== null) {
        return (
          <div>
            <h2>
              {moment(single_class.class_date).format("DD MMM YYYY HH:mm")}
            </h2>
            <h3>
              {renderIcon(single_class)} {renderName(single_class)}
            </h3>
            <p>Spot: {reservation.spot}</p>
          </div>
        );
      }
    }
  };

  const renderButton = () => {
    if (reservation && reservation !== null) {
      if (reservation.attend) {
        return (
          <button disabled={true} className="btn btn-outline-success">
            Asistencia Registrada
          </button>
        );
      }
      return (
        <button
          className="btn btn-primary"
          onClick={() =>
            postAttend(class_reservation_id, true, reservation.single_class_id)
          }
        >
          Registrar Asistencia
        </button>
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="card text-center p-3">
        {renderCustomer()}
        {renderReservation()}
        {renderButton()}
      </div>
    </div>
  );
};

export default AdminSingleReservation;
