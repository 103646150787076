import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
import PaqueteForm from "../../components/paquetes/PaqueteForm";
import { AppConfigContext } from "../../context/AppConfigContext";
import { PackagesContext } from "../../context/PackageContext";

const AdminPaqueteForm = ({ class_package_id }) => {
  const {
    spinner,
    paquete,
    getPaqueteAdmin,
    setPropiedadPaquete,
    postPaquete,
    clearSinglePaquete,
    createPaquete,
  } = useContext(PackagesContext);

  const { single_class_enabled, videos_enabled } = useContext(AppConfigContext);

  useEffect(() => {
    if (isNaN(class_package_id)) {
      createPaquete();
    } else {
      getPaqueteAdmin(class_package_id);
    }
    return clearSinglePaquete;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [class_package_id]);

  return (
    <div className="container-fluid">
      <Link
        to="/myadmin/paquetes"
        className="btn btn-outline-secondary bold shadow-sm mb-3"
      >
        {"< "}Regresar a Paquetes
      </Link>
      <h1 className="mb-0">
        {isNaN(class_package_id) ? "Agregar" : "Editar"} Paquete
      </h1>
      <div className="card p-3  mt-3 shadow">
        {paquete && paquete !== null ? (
          <PaqueteForm
            single_class_enabled={single_class_enabled}
            videos_enabled={videos_enabled}
            modifier={setPropiedadPaquete}
            postPaquete={postPaquete}
            class_package={paquete}
            spinner={spinner}
          />
        ) : (
          <div className="spinner-border"></div>
        )}
      </div>
    </div>
  );
};

export default AdminPaqueteForm;
