import React, { useEffect } from "react";
import { Router } from "@reach/router";
import Login from "./views/Login";
import Panel from "./views/Panel";
import { useContext } from "react";
import { UserContext } from "./context/UserContext";
import Modal from "./components/common/Modal";
import SuccessAlert from "./components/common/SuccessAlert";
import ErrorAlert from "./components/common/ErrorAlert";
import Loading from "./views/Loading";
import { AppConfigContext } from "./context/AppConfigContext";

const Main = () => {
  const { user, userLoggedIn } = useContext(UserContext);

  const config = useContext(AppConfigContext);

  useEffect(() => {
    userLoggedIn();
    handleColors();
    handleMetaTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleColors = () => {
    const props = ["primary", "accent", "dark", "gray"];
    props.forEach((key) => {
      const color = config[key];
      document.documentElement.style.setProperty(`--${key}`, color);
    });
  };

  const handleMetaTags = () => {
    const { favicon, business_name } = config;
    var link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = favicon;
    }
    document.title = `Admin ${business_name}`;
  };

  return (
    <div className="container-fluid px-0">
      <Router>
        <Login path="/entrar" default />
        {user !== null ? (
          <Panel path="/myadmin/*" />
        ) : (
          <Loading path="/myadmin/*" />
        )}
      </Router>
      <Modal />
      <SuccessAlert />
      <ErrorAlert />
    </div>
  );
};

export default Main;
