import { RESERVATIONS_RECIBIDAS, SET_CLASS_RESERVATION } from "../types";

const ReservationsReducer = (state, { type, payload }) => {
  switch (type) {
    case RESERVATIONS_RECIBIDAS:
      return { ...state, reservations: payload };
    case SET_CLASS_RESERVATION:
      return { ...state, reservation: payload };
    default:
      return { ...state };
  }
};

export default ReservationsReducer;
