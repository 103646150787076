import React, { useContext, useEffect } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { ModalContext } from "../../context/ModalContext";
import { PackagesContext } from "../../context/PackageContext";
import ReservacionRow from "../../components/reservaciones/ReservacionRow";
import { PurchasesContext } from "../../context/PurchasesContext";
import RevokeForm from "../../components/purchases/RevokeForm";
import { hideModal } from "../../utils";
import CancelForm from "../../components/purchases/CancelForm";
import PurchasesTable from "../../components/purchases/PurchasesTable";
import InvoicesTable from "../../components/invoices/InvoicesTable";
import PanelTitle from "../../components/global/PanelTitle";
import AddPurchaseForm from "../../components/purchases/AddPurchaseForm";
import { UserContext } from "../../context/UserContext";
import { PaymentMethodsContext } from "../../context/PaymentMethodsContext";
import CustomerData from "../../components/customers/CustomerData";
import CustomerActions from "../../components/customers/CustomerActions";
import { navigate } from "@reach/router";
import { AppConfigContext } from "../../context/AppConfigContext";

const AdminSingleUsuario = ({ customer_id }) => {
  const {
    customer,
    getCustomer,
    clearCustomer,
    extenderAcceso,
    deleteCustomer,
  } = useContext(CustomerContext);
  const { paquetes, getPaquetes } = useContext(PackagesContext);

  const { user } = useContext(UserContext);

  const { modalComponent } = useContext(ModalContext);

  const { single_class_enabled } = useContext(AppConfigContext);

  const { payment_methods, getPaymentMethods } = useContext(
    PaymentMethodsContext
  );

  const { cancelPurchase, revokePurchase, editExpiration } =
    useContext(PurchasesContext);

  useEffect(() => {
    getPaymentMethods();
    getCustomer(customer_id);
    getPaquetes();
    return clearCustomer;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id]);

  const toggleModal = () => {
    modalComponent(
      "Agregar Acceso",
      <AddPurchaseForm
        customer={customer}
        extenderAcceso={extenderAcceso}
        paymentMethods={payment_methods}
        paquetes={paquetes}
      />
    );
  };

  const confirmDelete = () => {
    modalComponent(
      "Precaución",
      <div>
        <p>
          ¿Estás segura que deseas eliminar el cliente {customer.name}{" "}
          {customer.last_name} con el correo {customer.email}?
        </p>
        <p>Esta acción NO puede deshacerse.</p>
        <div className="row">
          <div className="col-6">
            <button
              className="btn btn-danger"
              onClick={() => deleteCustomer(customer_id)}
            >
              <i className="fa fa-trash"></i> Eliminar Cliente
            </button>
          </div>
          <div className="col-6 text-right">
            <button className="btn btn-link text-secondary" onClick={hideModal}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const confirmRevoke = (purchase) => {
    modalComponent(
      "Precaución",
      <RevokeForm
        purchase={purchase}
        customer_id={customer_id}
        revokePurchase={revokePurchase}
      />
    );
  };

  const confirmCancel = (purchase) => {
    modalComponent(
      "Precaución",
      <CancelForm
        purchase={purchase}
        customer_id={customer_id}
        cancelPurchase={cancelPurchase}
      />
    );
  };

  const renderUsuario = () => {
    if (customer && customer !== null) {
      return (
        <div className="row">
          <div className="col-12 col-md-6">
            <CustomerData
              customer={customer}
              handleEdit={() =>
                navigate(`/myadmin/customer/${customer_id}/edit`)
              }
            />
          </div>
          <div className="col-12 col-md-6">
            <CustomerActions customer={customer} />
          </div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  const renderReservaciones = () => {
    if (customer && customer !== null) {
      if (Array.isArray(customer.class_reservations)) {
        return customer.class_reservations.map((reservation) => (
          <ReservacionRow
            key={reservation.class_reservation_id}
            reservation={reservation}
          />
        ));
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="row pb-2 border-bottom mx-0 mb-3 align-items-center">
        <div className="col col-md-6 ps-0">
          <h1>Cliente</h1>
        </div>
        <div className="col col-md-6 pe-0 text-end"></div>
      </div>
      <div className="card mb-3">{renderUsuario()}</div>
      <div className="card p-3 pb-1  my-3">
        <PanelTitle title="Compras" onClick={toggleModal} />
        <PurchasesTable
          user={user}
          hideColumns={["type", "customer"]}
          editExpiration={editExpiration}
          purchases={customer !== null ? customer.purchases : []}
          confirmRevoke={confirmRevoke}
          confirmCancel={confirmCancel}
        />
      </div>
      <div className="card p-3 pb-1  my-3">
        <PanelTitle title="Cargos" hideButton />
        <InvoicesTable
          user={user}
          customer_id={customer_id}
          invoices={customer !== null ? customer.invoices : []}
        />
      </div>
      {single_class_enabled && (
        <div className="card my-3">
          <h3>Reservaciones</h3>
          <div className="row bg-light border py-2 my-2">
            <div className="col col-md-3 bold">Clase</div>
            <div className="col col-md-2 bold">Coach</div>
            <div className="col col-md-2 bold">Fecha</div>
            <div className="col col-md-3 bold">Reservada en</div>
            <div className="col col-md-2 bold">Status</div>
          </div>
          {renderReservaciones()}
        </div>
      )}
      <button className="btn btn-outline-danger mt-5" onClick={confirmDelete}>
        <i className="fa fa-trash"></i> Eliminar Cliente
      </button>
    </div>
  );
};

export default AdminSingleUsuario;
