import React from "react";
import Switch from "react-switch";

const SingleClassOptions = ({ class_package, modifier }) => {
  const { class_amount, is_special_event } = class_package;
  return (
    <div>
      <h4 className="border-bottom pb-2">Clases Presenciales</h4>
      <div className="row mb-3">
        <div className="col-6">
          <label>Evento Especial</label>
        </div>
        <div className="col-6">
          <Switch
            checked={is_special_event}
            onChange={(checked) => {
              modifier("is_special_event", checked);
              if (checked) modifier("is_subscription", false);
            }}
          />
        </div>
      </div>
      <label>Cantidad de Clases Presenciales</label>
      <input
        type="number"
        className="form-control mb-3"
        value={class_amount !== null ? class_amount : ""}
        onChange={(e) => modifier("class_amount", e.target.value)}
      />
    </div>
  );
};

export default SingleClassOptions;
