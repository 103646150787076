import React, { useContext, useEffect } from "react";
import { ClassInstructorContext } from "../../context/SingleClassContext";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import AgregarAsistente from "../../components/clases/AgregarAsistente";
import PanelTitle from "../../components/global/PanelTitle";
import ClaseForm from "../../components/clases/ClaseForm";
import { ReservationsContext } from "../../context/ReservationsContext";
import { displayDayTimeMonth } from "../../utils";
import AsistentesTable from "../../components/asistentes/AsistentesTable";

const AdminAsistentes = ({ single_class_id }) => {
  const {
    clase,
    clearClase,
    getAsistentes,
    postPayment,
    eliminarClase,
    updateGuestName,
    setPropiedadClase,
  } = useContext(ClassInstructorContext);

  const { postAttend } = useContext(ReservationsContext);

  const { eliminarReservacion } = useContext(ReservationsContext);

  const { user } = useContext(UserContext);

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getAsistentes(single_class_id);
    return clearClase;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addAsistente = () => {
    modalComponent(
      "Agregar Asistente",
      <AgregarAsistente single_class_id={single_class_id} />
    );
  };

  const handleEdit = () => {
    modalComponent(
      "Editar Clase",
      <ClaseForm
        single_class_id={single_class_id}
        modifier={setPropiedadClase}
        confirmDeleteClass={confirmDeleteClass}
      />
    );
  };

  const confirmDeleteClass = (clase) => {
    modalComponent(
      "Eliminar Clase",
      <div className="container-fluid px-0">
        <p>
          ¿Estás segura que deseas eliminar la clase {clase.description}? Esta
          acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() => eliminarClase(clase.class_id)}
        >
          Eliminar
        </button>
      </div>
    );
  };

  const confirmCancel = (customer) => {
    modalComponent(
      "¿Cancelar reservacion?",
      <div className="container-fluid">
        <p>
          ¿Estás segura que deseas cancelar la reservacion de {customer.name}?
          Esta acción NO puede deshacerse.
        </p>
        <button
          className="btn btn-danger"
          onClick={() =>
            eliminarReservacion(customer.class_reservation_id, () =>
              getAsistentes(single_class_id)
            )
          }
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderClassTypeName = () => {
    if (clase.class_type && clase.class_type !== null) {
      return clase.class_type.name;
    }
  };

  const renderLocationName = () => {
    if (clase.location && clase.location !== null) {
      return clase.location.name;
    }
  };

  const renderButtons = () => {
    if (["admin", "manager"].includes(user.role)) {
      return (
        <div>
          <button className="btn btn-sm btn-outline-dark" onClick={handleEdit}>
            <i className="fa fa-edit me-1"></i> Editar
          </button>
          <button
            className="btn btn-sm btn-outline-danger mx-3"
            onClick={confirmDeleteClass}
          >
            <i className="fa fa-trash me-1"></i> Eliminar
          </button>
        </div>
      );
    }
  };

  const renderClase = () => {
    if (clase && clase !== null) {
      return (
        <div className="row">
          <div className="col-12 col-md-6">
            <h4>Datos de Clase</h4>
            <p>{displayDayTimeMonth(clase.class_date)}</p>
            <p>{renderClassTypeName()}</p>
            <p>{renderLocationName()}</p>
            {renderButtons()}
          </div>
          <div className="col col-md-6"></div>
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return (
    <div className="contiainer-fluid px-3">
      <PanelTitle title="Clase Presencial" onClick={addAsistente} />
      <div className="card p-3 shadow-sm ">{renderClase()}</div>
      <AsistentesTable
        asistentes={clase !== null ? clase.asistentes : []}
        postPayment={postPayment}
        postAttend={postAttend}
        single_class_id={single_class_id}
        updateGuestName={updateGuestName}
        confirmCancel={confirmCancel}
        user={user}
      />
    </div>
  );
};

export default AdminAsistentes;
