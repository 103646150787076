import React, { useState, useEffect, useContext } from "react";
import ScheduleWeek from "./ScheduleWeek";
import moment from "moment";
import { ClassInstructorContext } from "../../context/SingleClassContext";

const Schedule = ({ locations, layout, isHome }) => {
  const [selected, setSelected] = useState(0);
  const [ubicacion, setUbicacion] = useState("");
  const [currentDay, setCurrentDay] = useState(0);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [weeks, setWeeks] = useState("");
  const [month, setMonth] = useState(moment().month());

  const { days, update, clearUpdate, getSchedule } = useContext(
    ClassInstructorContext
  );

  useEffect(() => {
    fetchSchedule();
    clearUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, update]);

  useEffect(() => {
    if (Array.isArray(days)) {
      const weeksNumber = Math.ceil(days.length / 7);
      setWeeks(weeksNumber);
      setCurrentDay(moment().day());
      if (month === moment().month()) {
        const startCurrentWeek = moment().startOf("week");
        const currentDays = days.filter((day) =>
          moment(day.date).isAfter(startCurrentWeek)
        );
        const currentWeekIndex = Math.abs(
          parseInt((days.length - currentDays.length) / 7)
        );
        setCurrentWeek(currentWeekIndex);
        if (currentWeekIndex > 0) {
          setSelected(currentWeekIndex);
        }
      } else {
        setSelected(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  const fetchSchedule = () => {
    const start_date = moment(month + 1, "M")
      .startOf("month")
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
    const end_date = moment(month + 1, "M")
      .endOf("month")
      .endOf("isoWeek")
      .format("YYYY-MM-DD");
    getSchedule(start_date, end_date);
  };

  const renderdays = () => {
    if (days && days !== null) {
      if (!hasClases()) {
        return (
          <div className="row mt-4">
            <p className="px-0 mb-0">No hay clases presenciales programadas.</p>
          </div>
        );
      }
      if (layout === "week") {
        let weekStart = selected * 7;
        if (currentWeek === selected) {
          weekStart += currentDay - 1;
        }
        const week = days.slice(weekStart, weekStart + 7);
        if (!hasClases(week)) {
          return (
            <div className="row">
              <p className="px-0 mb-0 text-center">
                No hay clases programadas.
              </p>
            </div>
          );
        }
        return (
          <ScheduleWeek
            week={week}
            layout="week"
            location={ubicacion}
            isHome={isHome}
          />
        );
      }
      const weeks = Math.ceil(days.length / 7);
      return new Array(weeks)
        .fill(1)
        .map((one, index) => (
          <ScheduleWeek
            key={index}
            isHome={isHome}
            layout="month"
            hideMobile={index !== currentWeek}
            week={days.slice(index * 7, index * 7 + 7)}
            location={ubicacion}
          />
        ));
    }
  };

  const renderLocations = () => {
    if (Array.isArray(locations)) {
      if (locations.length > 1) {
        return (
          <div className="form-group mb-4">
            <label>Buscar por estudio</label>
            <select
              className="form-control"
              onChange={(e) => setUbicacion(e.target.value)}
            >
              {renderEstudios()}
            </select>
          </div>
        );
      }
    }
  };

  const renderEstudios = () => {
    if (locations && locations !== null) {
      return [
        <option key="all" value="">
          Todos los Estudios
        </option>,
        ...locations.map((location) => (
          <option key={location.location_id} value={location.location_id}>
            {location.name}
          </option>
        )),
      ];
    }
  };

  const hasClases = () => {
    if (days && days !== null) {
      let total = 0;
      days.forEach((day) => {
        total += day.details.length;
      });
      return total > 0;
    }
  };

  return (
    <div className="container-fluid px-0" style={{ overflowX: "hidden" }}>
      <div className="row">
        <div className="col-12 col-md-4 my-2">
          <div className="row align-items-center">
            <div className="col-4">
              <button
                className="btn btn-light border"
                disabled={month === 0}
                onClick={() => {
                  if (month > 0) {
                    setMonth(month - 1);
                  }
                }}
              >
                <i className="fa fa-chevron-left"></i>
              </button>
            </div>
            <div className="col-4 text-center">
              <h2 className="mb-0">{moment(month + 1, "M").format("MMM")}</h2>
            </div>
            <div className="col-4 text-right">
              <button
                className="btn btn-light border"
                disabled={month === 11}
                onClick={() => setMonth(month + 1)}
              >
                <i className="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div
          className={`col-12 col-md-8 my-2 ${
            layout !== "week" ? "hide-desktop" : ""
          }`}
        >
          <div className="row align-items-center">
            <div className="col-3 text-left">
              <button
                className="btn btn-light shadow-sm border"
                disabled={selected === currentWeek}
                onClick={() => {
                  if (selected > currentWeek) {
                    setSelected(selected - 1);
                  }
                }}
              >
                <i className="fa fa-chevron-left"></i>
              </button>
            </div>
            <div className="col-6 text-center">
              <h4 className="mb-0">Semana {selected + 1}</h4>
            </div>
            <div className="col-3 text-right">
              <button
                className="btn btn-light shadow-sm border"
                disabled={selected === weeks - 1}
                onClick={() => {
                  if (selected < weeks - 1) {
                    setSelected(selected + 1);
                  }
                }}
              >
                <i className="fa fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 my-2">{renderLocations()}</div>
      </div>
      <div className="container-fluid schedule-container">{renderdays()}</div>
    </div>
  );
};

export default Schedule;
