import React, { useContext } from "react";
import { ClassInstructorContext } from "../../context/SingleClassContext";
import { ModalContext } from "../../context/ModalContext";
import { UserContext } from "../../context/UserContext";
import { navigate } from "@reach/router";
import ClaseForm from "./ClaseForm";
import moment from "moment";

const ScheduleClass = ({ singleClass }) => {
  const { modalComponent } = useContext(ModalContext);
  const { setPropiedadClase, eliminarClase } = useContext(
    ClassInstructorContext
  );

  const { user } = useContext(UserContext);

  const handleEdit = () => {
    modalComponent(
      "Agregar Clase",
      <ClaseForm
        single_class_id={singleClass.single_class_id}
        modifier={setPropiedadClase}
      />
    );
  };

  const handleDelete = () => {
    modalComponent(
      "Eliminar Clase",
      <div>
        <p>
          ¿Estás segura que deseas eliminar la clase de{" "}
          {singleClass.class_type.name} del{" "}
          {moment(singleClass.class_date).format("DD MMM YYY")}?
        </p>
        <button
          className="btn btn-danger btn-sm"
          onClick={() => eliminarClase(singleClass.single_class_id)}
        >
          Eliminar
        </button>
      </div>
    );
  };

  const renderButtons = () => {
    if (["admin", "manager"].includes(user.role)) {
      return (
        <div>
          <button className="btn btn-primary btn-sm mx-2" onClick={handleEdit}>
            <i className="fa fa-edit" />
          </button>
          <button
            className="btn btn-outline-danger btn-sm mx-2"
            onClick={handleDelete}
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      );
    }
  };

  const renderInstructors = () => {
    if (singleClass.class_instructors.length === 1) {
      return singleClass.class_instructors[0].instructor.name;
    }
    return singleClass.class_instructors.map((class_instructor) => (
      <span>{class_instructor.instructor.name}</span>
    ));
  };

  return (
    <div className="schedule-class p-3 my-2">
      <p className="mb-1 bold">
        <i className={singleClass.icon} /> {singleClass.class_type.name}
      </p>
      <p className="mb-1 small">{singleClass.description}</p>
      <p className="font-weight-bold mb-1">
        <i className="far fa-clock"></i>{" "}
        {moment(singleClass.class_date).utc().format("HH:mm")}
      </p>
      <p className="mb-1">{renderInstructors()}</p>

      <p
        className="mb-2"
        onClick={() =>
          navigate(`/myadmin/asistentes/${singleClass.single_class_id}`)
        }
      >
        <i className="fa fa-user me-1" /> {singleClass.reservations} /{" "}
        {singleClass.capacity}
      </p>
      {renderButtons()}
    </div>
  );
};

export default ScheduleClass;
