import api from "./api";

const route = "/programs";

const ProgramsService = {
  getPrograms: () => api.get(route),
  getSingleProgram: (url) => api.get(`${route}/${url}`),
};

export default ProgramsService;
