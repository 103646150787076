import { getArgs } from "../utils";
import api from "./api";

const route = "/videos";

const VideosService = {
  getVideos: (params) => api.get(`${route}/admin/all?${getArgs(params)}`),
  getAllVideos: (page, filters) =>
    api.get(`${route}/admin/all?page=${page}&${getArgs(filters)}`),
  getFavoritos: (query) => api.get(`/favoritos?${getArgs(query).slice(1)}`),
  getSingleVideo: (video_id) => api.get(`${route}/admin/${video_id}/video`),
  postFavorito: (video_id) => api.post("/favoritos", { video_id }),
  postVideo: (video) => api.post(route, { ...video }),
  putVideo: (video) => api.put(route, { ...video }),
  deleteFavorito: (video_id) => api.delete(`/favoritos/${video_id}`),
  deleteVideo: (video_id) => api.delete(`${route}/${video_id}`),
};

export default VideosService;
