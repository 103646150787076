import React, { useContext } from "react";
import StatusBadge from "../common/StatusBadge";
import { formatMonto } from "../../utils";
import { Link } from "@reach/router";
import moment from "moment";
import { ModalContext } from "../../context/ModalContext";
import { PurchasesContext } from "../../context/PurchasesContext";
import EditPurchaseForm from "./EditPurchaseForm";

const PurchaseRow = ({ user, paquete, hideColumns }) => {
  const { modalComponent } = useContext(ModalContext);

  const { setPurchase } = useContext(PurchasesContext);

  const handleEdit = () => {
    setPurchase(paquete);
    modalComponent("Editar Compra", <EditPurchaseForm />);
  };

  const canEdit = () => {
    return paquete.status !== "revoked";
  };

  const renderPaymentMethod = () => {
    if (paquete.payment_method && paquete.payment_method !== null) {
      return paquete.payment_method.name;
    }
  };

  const renderExpiration = () => {
    let createdAt = moment(paquete.createdAt);
    if (paquete.subscription_id !== null) {
      if (paquete.status === "active") {
        let day = createdAt.day();
        let nextMonth = moment();
        let currDay = moment().day();
        if (day < currDay) {
          nextMonth = moment(nextMonth, "M").add(
            paquete.subscription_interval,
            paquete.subscription_period
          );
        }
        return nextMonth
          .startOf("month")
          .add(day, "days")
          .format("DD MMM YYYY");
      }
      return createdAt
        .add(paquete.subscription_interval, paquete.subscription_period)
        .format("DD MMM YYYY");
    }
    return createdAt
      .add(paquete.expiration_days, "days")
      .format("DD MMMM YYYY");
  };

  const renderType = () => {
    if (!Array.isArray(hideColumns) || !hideColumns.includes("type")) {
      return (
        <td>{paquete.invoice_id ? <i className="fa fa-undo"></i> : ""}</td>
      );
    }
  };

  const renderCustomer = () => {
    if (
      paquete.customer &&
      paquete.customer !== null &&
      (!Array.isArray(hideColumns) || !hideColumns.includes("customer"))
    ) {
      return (
        <td>
          <Link to={`/myadmin/customer/${paquete.customer.customer_id}`}>
            <i className="fa fa-eye"></i> {paquete.customer.name}{" "}
            {paquete.customer.last_name}
          </Link>
        </td>
      );
    }
  };

  const isFirstPurchase = () => {
    if (paquete.customer && paquete.customer !== null) {
      if (Array.isArray(paquete.customer.invoices))
        return paquete.customer.purchases.length === 1;
    }
  };

  const renderDiscountCode = () => {
    if (paquete.discount && paquete.discount !== null) {
      return paquete.discount.code;
    }
  };

  const renderClassPackage = () => {
    if (paquete.class_package && paquete.class_package !== null) {
      return paquete.class_package.title;
    }
  };

  const isPayPal = () => {
    if (paquete.payment_method_id === 3) {
      if (paquete.subscription_id !== null) {
        return (
          <a
            href={`https://www.paypal.com/billing/subscriptions/${paquete.subscription_id}`}
            className="btn btn-sm btn-outline-secondary"
            rel="noreferrer"
            target="_blank"
          >
            <i className="fab fa-paypal"></i>
          </a>
        );
      } else if (paquete.order_id !== null) {
        return (
          <a
            href={`https://www.paypal.com/activity/payment/${paquete.order_id}`}
            className="btn btn-sm btn-outline-secondary"
            rel="noreferrer"
            target="_blank"
          >
            <i className="fab fa-paypal"></i>
          </a>
        );
      }
    }
  };

  const isStripe = () => {
    if (paquete.payment_method_id === 1) {
      if (paquete.subscription_id !== null) {
        return (
          <a
            href={`https://dashboard.stripe.com/subscriptions/${paquete.subscription_id}`}
            className="btn btn-sm btn-outline-secondary"
            rel="noreferrer"
            target="_blank"
          >
            <i className="fab fa-stripe-s"></i>
          </a>
        );
      } else if (paquete.order_id !== null) {
        return (
          <a
            href={`https://dashboard.stripe.com/payments/${paquete.order_id}`}
            className="btn btn-sm btn-outline-secondary"
            rel="noreferrer"
            target="_blank"
          >
            <i className="fab fa-stripe-s"></i>
          </a>
        );
      }
    }
  };

  return (
    <tr className="small border-bottom align-middle hover-light">
      <td>
        {paquete.invoice_id ? paquete.invoice_id : paquete.purchase_id}{" "}
        {paquete.admin_enabled && <i className="fa fa fa-user-shield"></i>}{" "}
        {isFirstPurchase() && <i className="fa fa-star text-warning"></i>}
      </td>
      {renderType()}
      {renderCustomer()}
      <td>{renderClassPackage()}</td>
      <td>{moment(paquete.createdAt).local().format("DD MMM YYYY HH:mm")}</td>
      <td>
        {paquete.is_gift && <i className="fas fa-gift me-1"></i>}
        {user.role === "admin" && (
          <>
            {"$"}
            {formatMonto(paquete.amount)} MXN
          </>
        )}
      </td>
      <td>{renderExpiration()}</td>
      <td>{renderPaymentMethod()}</td>
      <td>
        <StatusBadge status={paquete.status} date={paquete.updatedAt} />
      </td>
      <td>{renderDiscountCode()}</td>
      <td>
        {canEdit() && (
          <button
            className="btn btn-sm btn-outline-dark me-2"
            onClick={handleEdit}
          >
            <i className="fa fa-edit"></i>
          </button>
        )}
        {isPayPal()}
        {isStripe()}
      </td>
    </tr>
  );
};

export default PurchaseRow;
